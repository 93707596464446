import { IncomingMessage } from 'http'
import { TraceState } from 'types/newrelic'
import { getClassyAcessToken, refreshClassyAccessToken } from 'services/auth'
import { logger } from 'utils/logger'

export interface KongFetchOptions {
  originalRequest?: IncomingMessage
}

/**
 * fetch() wrapper for calling any Classy API service (APIv2, Cart API, etc) with the necessary
 * access token to properly authenticate the request. This wrapper also includes logic for
 * refreshing the access token when necessary.
 *
 * In general, kongFetch() should not be used directly. See apiv2.ts and cart.ts for API-specific
 * examples that use this function.
 */
export async function kongFetch(
  path: string,
  fetchOptions?: RequestInit,
  classyOptions?: KongFetchOptions,
): Promise<Response> {
  let url: string

  try {
    /**
     * Construct the full URL by combining the base URL and API path.
     * Use URL object to check validity, but immediately convert to a string for portability.
     * Bail early if combined URL is invalid (per the URL object, not exhaustive).
     */
    url = String(new URL(path, process.env.KONG_BASE_URI))
  } catch (e) {
    logger('error', new Error('Invalid Kong URL', { cause: e }), { context: { path } })
    throw e
  }

  let token = await getClassyAcessToken()

  // Prepare headers for fetch
  const headers: HeadersInit = {
    ...fetchOptions?.headers,
    ...getTraceHeaders(classyOptions?.originalRequest),
    Authorization: `Bearer ${token}`,
  }

  let response

  // Call fetch with the constructed URL and options
  try {
    response = await fetch(url, {
      ...fetchOptions,
      headers,
    })
  } catch (e) {
    logger('error', new Error('Kong fetch failed', { cause: e }), { context: { url } })
    throw e
  }

  /**
   * We will automatically refresh the token if it's about to expire, but
   * as a sanity check we'll also handle a potential invalid_token error
   * here, and refresh it manually before retrying.
   */
  if (response.status === 401) {
    const responseBody = await response.json()

    // Check if the error is due to an expired token
    if (responseBody.error === 'invalid_token') {
      // Refresh the API token
      try {
        token = await refreshClassyAccessToken()
      } catch (e) {
        logger('error', new Error('Unable to refresh Classy access token', { cause: e }))
        throw e
      }

      // Update the Authorization header with the new token
      const updatedFetchOptions = {
        ...fetchOptions,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }

      // Retry the API call with the new token
      try {
        response = await fetch(url, updatedFetchOptions)
      } catch (e) {
        logger('error', new Error('Retrying Kong fetch with new token failed', { cause: e }))
        throw e
      }
    }
  }

  return response
}

/**
 * Adds New Relic trace headers from the original request.
 */
function getTraceHeaders(originalRequest?: IncomingMessage) {
  const headers: TraceState = {}

  if (originalRequest?.headers?.tracestate) {
    headers.tracestate = originalRequest.headers.tracestate as string
  }

  if (originalRequest?.headers?.traceparent) {
    headers.traceparent = originalRequest.headers.traceparent as string
  }

  if (originalRequest?.headers?.newrelic) {
    headers.newrelic = originalRequest.headers.newrelic as string
  }

  return headers
}
