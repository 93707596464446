import Bugsnag from '@bugsnag/js'
import { pinoLogger } from './pino'

/**
 * Start Bugsnag
 */
export const start = async (bugsnagApiKey?: string, appEnv?: string, nextBuildId?: string) => {
  if (appEnv === 'local' || !bugsnagApiKey) return

  Bugsnag.start({
    apiKey: bugsnagApiKey,
    appVersion: `${appEnv}-${nextBuildId}`,
    plugins: [],
    releaseStage: appEnv,
    /**
     * Note, logger is only used when the log is sent to the console instead of BugSnag.
     * None of Pino's configuration options change/affect what BugSnag does.
     */
    logger: pinoLogger,
  })
}

/**
 * Returns the Bugsnag plugin for use with AWS Lambda functions.
 *
 * ! FYI, not sure if we currently use this. It might be a Vercel-only thing.
 */
export const getServerlessHandler = () => Bugsnag.getPlugin('awsLambda')?.createHandler()
