import React from 'react'
import { useRouter } from 'next/router'
import { Member } from 'models/member'
import { CLASSY_LOGIN_COOKIE_NAME, SsoStatus } from 'models/sso'
import { getAnalyticsInstance } from 'services/analytics'
import { redirect } from 'services/sso'

/**
 * This function uses the Classy SSO SDK to fetch the status of a currently logged in member and
 * compares it to the member that currently has a session in whammy if one exists in either case.
 * There are a few scenarios to account for:
 * 1) if a member is not logged in to SSO but has a session in whammy: log them out of whammy
 * and refresh the page
 * 2) if a member is not logged in to SSO and does not have a session in whammy: return
 * 3) if a member is logged into SSO but does not have a session in whammy: log them in to whammy
 * using the iframe created by the SDK (which is looking for a div with class "classy-login-iframe")
 * 4) if a member is logged into SSO, has a (stale) session in whammy, but member ids don't match:
 * re-log in the SSO user in whammy using the SDK so that member context in whammy matches SSO
 * 5) if a member is logged into SSO, has a session in whammy, and member ids match: set the member
 * context to be the one already fetched and stored in the session in whammy
 *
 * If a member context is returned at the end of the function, we should assume it has been
 * validated and matches the member currently logged in to SSO.
 *
 * Note: we reload the page if the member context changes and the user is on a page that requires
 * authorization (currently only the preview page) so that we can ensure the member is properly
 * authorized.
 */
export const useSsoMember = (isSsoSdkLoaded: boolean) => {
  /**
   * Initial value of member is set to undefined. This will allow trackPageViewEvent on CampaignPage
   * to fire after useSsoMember checks whether a user is logged in or not.
   *
   * If isSsoSdkLoaded, updateLoggedInMember fires and sets the member to be null or
   * the member's value .
   */
  const [member, setMember] = React.useState<Member | null | undefined>(undefined)

  const router = useRouter()

  // Handle member changes to trigger analytics.identify
  React.useEffect(() => {
    if (member && member.id) {
      const analytics = getAnalyticsInstance()

      analytics.once('ready', () => {
        analytics.identify(String(member.id), {
          hostName: window.location.hostname,
          visitorType: 'member',
        })
      })
    }
  }, [member])

  React.useEffect(() => {
    let ignore = false

    const previewRoute = '/campaign/[campaignId]/preview'

    const updateLoggedInMember = async () => {
      window.Classy.ready(async () => {
        let idpAccessToken

        if (window.Classy.getCookie && window.Classy.getCookie(CLASSY_LOGIN_COOKIE_NAME)) {
          idpAccessToken = JSON.parse(
            decodeURIComponent(window.Classy.getCookie(CLASSY_LOGIN_COOKIE_NAME) || '{}'),
          )
        }

        window.Classy.status(async (status: SsoStatus) => {
          try {
            const memberResponse = await fetch('/api/member')
            const member = await memberResponse.json()

            if (!status.is_logged_in && !status.has_authorized) {
              // case 1
              if (member) {
                await fetch('/api/auth/logout')

                if (router.route === previewRoute) {
                  redirect(window.location.href)
                }
              }

              // case 2
              if (!ignore) {
                /**
                 * We know a user is not logged in so set member to null
                 */
                setMember(null)
              }

              return
            }

            // cases 3 and 4
            if (status?.id !== member?.id) {
              window.Classy.parseDom(async (response: { authorization_code?: string }) => {
                if (response.authorization_code) {
                  await fetch(`/api/auth/iframeCallback?code=${response.authorization_code}`)

                  const memberResponse = await fetch('/api/member')
                  const member = await memberResponse.json()

                  if (!ignore && member) {
                    setMember(member)
                  }

                  if (router.route === previewRoute) {
                    redirect(window.location.href)
                  }
                }
                return
              })
            }

            // case 5
            if (!ignore && member) {
              setMember(member)
            }
          } catch (e) {
            await fetch('/api/auth/logout')

            // If we fail to fetch and validate our session with SSO, logout the user on whammy
            if (router.route === previewRoute) {
              redirect(window.location.href)
            }
          }
        }, idpAccessToken?.access_token)
      })
    }

    if (isSsoSdkLoaded) {
      updateLoggedInMember()
    }

    return () => {
      ignore = true
    }
  }, [isSsoSdkLoaded, router.route])

  return member
}
