import { KongFetchOptions, kongFetch } from './kong'

/**
 * fetch() wrapper for calling any APIv2 endpoint that requires authentication.
 *
 * Automatically:
 * - Preprends "/2.0" to the path
 * - Adds header "Content-Type: application/json"
 * - Uses kongFetch() to add required access token
 * - Returns the results of response
 *
 * Example:
 * const data = await APIv2Fetch<SearchResults>('/search/pet?cat=1', {
 *   method: 'POST',
 *   body: JSON.stringify({ color: 'tuxedo', solitary: false })
 * })
 */
export async function APIv2Fetch(
  path: string,
  fetchOptions?: RequestInit,
  classyOptions?: KongFetchOptions,
): Promise<Response> {
  const versionedPath = `/2.0${path}`

  const fetchOptionsWithCommon = {
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      ...fetchOptions?.headers,
    },
  }

  return kongFetch(versionedPath, fetchOptionsWithCommon, classyOptions)
}
