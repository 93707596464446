let fbLoaded = false

/**
 * Custom Facebook Pixel Analytics plugin.
 *
 * @param config
 * @returns
 */
export const facebookPixel = (config: { pixelId: string }) => {
  const { pixelId } = config

  return {
    name: 'facebook-pixel',
    initialize: () => {
      if (typeof window !== 'undefined' && !fbLoaded) {
        if (!window.fbq) {
          console.log('Facebook Pixel not loaded')
          return false
        }

        window.fbq('init', pixelId)

        fbLoaded = true
        return true
      }
    },
    page: ({ payload }: { payload: { event: string; properties: { event_id: number } } }): void => {
      if (!fbLoaded) return

      window.fbq('track', 'PageView', {}, { eventID: payload.properties.event_id })
    },
    track: ({ payload }: { payload: { event: string; properties: { event_id: number } } }) => {
      if (!fbLoaded) return

      window.fbq(
        'track',
        payload.event,
        { ...payload.properties },
        { eventID: payload.properties.event_id },
      )
    },
    loaded: (): boolean => {
      return fbLoaded
    },
  }
}
