import { css, Global } from '@emotion/react'
import { AppProps } from 'next/app'

import {
  normalizeCss,
  pageResetCss,
  fullPageHeightCss,
  pageContainerCss,
  DEFAULT_THEME,
  CLASSY_DESIGN_TOKENS,
  type CampaignTheme,
} from '@classy/campaign-page-blocks'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { start } from 'utils/bugsnag'
import { resolveGoogleFonts } from 'utils/googleFonts'
import { useLoadAnalytics, FacebookPixelScript, resolveAnalyticsSettings } from 'services/analytics'
import { ConsentProvider } from 'services/transcend'

const globalResetCss = (theme: CampaignTheme) => css`
  :root {
    /* Base tokens */
    ${CLASSY_DESIGN_TOKENS}
    ${DEFAULT_THEME}
    ${resolveGoogleFonts(theme)}
  }

  ${normalizeCss}
  ${pageResetCss}
  ${fullPageHeightCss}

  body {
    font-family: var(--classy-font__font-family--base);
    ${pageContainerCss}
  }

  /**
   * In some cases, a tracking service adds a non-hidden image to the end of the <body>, which
   * causes double scroll bars. The double scroll bars occurs because html, body, and div#__next
   * have a height of 100% (see fullPageHeightCss). When an image is added after div#__next, the
   * body is forced to be larger than height 100%, triggering an extra scroll bar.
   *
   * For now, only targeting direct children of body.
   */
  body > img {
    display: block;
    height: 0;
  }
`

const App = ({ Component, pageProps }: AppProps) => {
  // FYI, pageProps is initially empty, hence why ?. is used for all properties
  const { __ENV, pageConfig, theme } = pageProps

  // Initialize Bugsnag
  start(__ENV?.bugsnagApiKey, __ENV?.appEnv, __ENV?.nextBuildId)

  const campaignId = pageConfig?.campaignId
  const orgId = pageConfig?.orgId

  /**
   * resolveAnalyticsSettings filters and/or transforms analyticsServiceSettings
   * and organizationChannels data, and returns an array of objects combining
   * analyticsServiceSettings and organizationChannels.
   *
   * If analyticsServiceSettings and organizationChannels are both false, resolveAnalyticsSettings
   * will return an empty array.
   */
  const analyticsSettings = resolveAnalyticsSettings(
    pageConfig?.analyticsServiceSettings ?? [],
    pageConfig?.organizationChannels ?? [],
    orgId,
  )

  const loadFacebookPixelScript = analyticsSettings.some(
    (setting) => setting.service_name === 'facebook_pixel',
  )
  const isAnalyticsLoaded = useLoadAnalytics({
    analyticsSettings: analyticsSettings,
    environment: __ENV?.appEnv,
    campaignId,
    orgId,
    hasCartEnabled: pageConfig?.isCartEnabled || false,
    heapAppId: __ENV?.heapAppId,
  })

  return (
    <ConsentProvider airgapSrc={__ENV?.airgapSrcUrl}>
      <Global styles={globalResetCss(theme)} />
      <ErrorBoundary>
        <Component {...{ ...pageProps, isAnalyticsLoaded }} />
      </ErrorBoundary>
      {loadFacebookPixelScript && <FacebookPixelScript />}
    </ConsentProvider>
  )
}

export default App
