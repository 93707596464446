import type { AnalyticsPlugin } from 'analytics'
import type { SuperProperties, AnalyticsPayload } from './analytics.model'
import { flattenObjectForTealium } from './dataLayerConverters'

type TealiumSettings = SuperProperties & {
  account: string
  profile: string
}

type PluginData = {
  config: TealiumSettings
  payload: AnalyticsPayload
}

/**
 * * Custom Tealium analytics plugin
 */
export const tealium = (settings: TealiumSettings): AnalyticsPlugin => ({
  name: 'tealium',
  config: flattenObjectForTealium(settings),

  initialize({ config }: PluginData): void {
    const { account, profile, ...superProperties } = config

    // * Init Tealium Script
    const tealiumScript = document.createElement('script')
    tealiumScript.type = 'text/javascript'
    tealiumScript.async = true
    tealiumScript.src = `https://tags.tiqcdn.com/utag/${account}/${profile}/${superProperties.environment}/utag.js`
    // * Inject Tealium Script into the DOM
    document.body.prepend(tealiumScript)

    // * Init Universal Data Object Script
    const utagScript = document.createElement('script')
    utagScript.type = 'text/javascript'
    utagScript.async = true
    utagScript.text = `var utag_data = ${JSON.stringify(superProperties)}`
    // * Inject Universal Data Object Script into the DOM
    document.body.prepend(utagScript)

    // * Init A/B Test Script
    const abTestScript = document.createElement('script')
    abTestScript.type = 'text/javascript'
    abTestScript.async = true
    abTestScript.src = `https://tags.tiqcdn.com/utag/${account}/${profile}/${superProperties.environment}/utag.sync.js`
    // * Inject A/B Test Script into the DOM
    document.body.prepend(abTestScript)
  },
  page({ payload }: PluginData): void {
    const flatProperties = flattenObjectForTealium(payload.properties)
    window.utag.view(flatProperties)
  },
  track({ payload }: PluginData): void {
    const flatProperties = flattenObjectForTealium({
      tealium_event: payload.event,
      ...payload.properties,
    })
    window.utag.link(flatProperties)
  },
  loaded() {
    return !!window.utag
  },
})
