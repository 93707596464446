import type { AnalyticsInstance } from 'analytics'
import type { AnalyticsPayload, SuperProperties } from './analytics.model'
import { getCookieValue } from 'utils/getCookieValue'

export type HeapCookie = {
  userId: string
  pageviewId: string
  sessionId: string
}

type PluginData = {
  config: SuperProperties
  payload: AnalyticsPayload
  instance: AnalyticsInstance
}

/**
 * Custom heap analytics plugin:
 * https://getanalytics.io/plugins/writing-plugins/
 * https://developers.heap.io/docs/web
 */
export const heap = (heapAppId: string, heapSuperProperties: SuperProperties) => ({
  name: 'heap',
  config: {
    ...heapSuperProperties,
    campaignType: 'studio',
  },
  initialize({ config: superProperties }: PluginData): void {
    if (!!window.heap) {
      window.heap.clearEventProperties()

      // Retrieve Session ID cookie
      const sessionId = getCookieValue('classy-session-id')

      // Add super properties and Classy session id if it exists
      window.heap.addEventProperties({
        ...superProperties,
        ...(sessionId && { 'classy-session-id': sessionId }),
      })
    }
  },
  page({ payload }: PluginData): void {
    const { properties } = payload

    window.heap.track('Page View', properties)
  },
  track({ payload }: PluginData): void {
    const { event, properties } = payload

    if (event) {
      window.heap.track(event, properties)
    }
  },
  identify({ payload }: { payload: { userId: string } }): void {
    const { userId } = payload

    if (userId) {
      window.heap.identify(userId)
    }
  },
  loaded(): boolean {
    return !!window.heap
  },
})
